import { defineComponent as _defineComponent } from 'vue'
import TheClock from "@/components/TheClock.vue";
import TheFooter from "@/components/TheFooter.vue";
import TheHeader from "@/components/TheHeader.vue";
import BrandOwnerTable from "@ems/containers/BrandOwner/Table/index.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'TenantInvitations',
  setup(__props, { expose: __expose }) {
  __expose();


const __returned__ = { TheClock, TheFooter, TheHeader, BrandOwnerTable }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})